import { get } from "lodash";
import { httpClient } from "../../httpClient";

// --- General Organization ---

export async function fetchCompanyData() {
  try {
    const res = await httpClient.get(`/company`);
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function createCompany() {
  try {
    const res = await httpClient.post("/company");

    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function updateCompanyProfile(body) {
  try {
    const res = await httpClient.put(`/company`, {
      company: body,
    });
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function updateCompanyPicture(body) {
  try {
    const res = await httpClient.post(`/company/image`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function fetchCompanyPicture() {
  try {
    const res = await httpClient.get(`/company/image`, {
      responseType: "arraybuffer",
      transformResponse: (data, headers) => {
        return {
          data: {
            buffer: data,
            contentType: headers["content-type"],
          },
        };
      },
    });

    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function getCompanyImageById({ id }) {
  try {
    const res = await httpClient.get(`/company/${id}/image`, {
      responseType: "arraybuffer",
      transformResponse: (data, headers) => {
        return {
          data: {
            buffer: data,
            contentType: headers["content-type"],
          },
        };
      },
    });

    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

// --- Legal Representative of Organization ---

export async function createLegalProfile(body) {
  return httpClient.post(`/company/people/legal`, {
    person: body,
  });
}

export async function updateLegalProfile(personId, body) {
  return httpClient.put(`/company/people/${personId}`, {
    person: body,
  });
}

export async function deleteLegalProfile() {
  try {
    const res = await httpClient.delete(`/company/people/legal/remove`);
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

// --- Employees of Organization ---

export async function getCompanyEmployees() {
  try {
    const res = await httpClient.get(`/company/employees`);
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function createEmployeeProfile(body) {
  try {
    const res = await httpClient.post(`/company/employees`, {
      employee: { ...body, transport_type: "private_motor_vehicle" },
    });
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function updateEployeeProfile({ id, body }) {
  try {
    const res = await httpClient.put(`/company/employees/${id}`, {
      employee: { ...body, transport_type: "private_motor_vehicle" },
    });
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function deleteEployeeProfile({ id }) {
  try {
    const res = await httpClient.delete(`/company/employees/${id}`);
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function getEmployeeRecord({ employee_id, pageSize, pageIndex }) {
  try {
    const res = await httpClient.get(`/company/records`, {
      params: {
        employee_id,
        pageSize: pageSize,
        page: pageIndex
      },
    });
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

// --- General Offices of Organization ---

export async function deleteCompany() {
  try {
    const res = await httpClient.delete(`/company`);
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function fetchAllOffices({ paginate, pageSize, pageIndex }) {
  try {
    const res = await httpClient.get(`/company/offices`, {
      params: {
        paginate,
        pageSize,
        page: pageIndex + 1,
      },
    });
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function fetchOfficeByOfficeId(officeId) {
  try {
    const res = await httpClient.get(`/company/offices/${officeId}`);
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function createOfficeProfile() {
  try {
    const res = await httpClient.post(`/company/offices`);
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function updateOfficeProfile(officeId, body) {
  try {
    const res = await httpClient.put(`/company/offices/${officeId}`, {
      office: body,
    });
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function deleteOfficeByOfficeId(officeId) {
  try {
    const res = await httpClient.delete(`/company/offices/${officeId}`);
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function categoriesOfficieRegister(officeId, body) {
  return httpClient.put(`/company/offices/${officeId}/registers`, {
    categories: body,
  });
}

// --- Profiles ---

export async function fetchAllCompanyProfiles({ pageIndex, pageSize, paginate, filters }) {
  try {
    const res = await httpClient.get(`/company/profiles`, {
      params: {
        paginate,
        pageSize,
        page: pageIndex + 1,
        ...(filters?.type && { type: filters?.type }),
      },
    });

    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function fetchAllCompanyEmployees({ pageIndex, pageSize, paginate, filters }) {
  try {
    const res = await httpClient.get(`/company/employees`, {
      params: {
        paginate,
        pageSize,
        page: pageIndex + 1,
        ...(filters?.type && { type: filters?.type }),
      },
    });

    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function fetchCompanyEmployeeDetails(id) {
  try {
    const res = await httpClient.get(`/company/employees/${id}`);
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function sendCompanyProfiles(body) {
  return httpClient.post(`/company/profiles`, body);
}

export async function deleteCompanyProfile(profileId) {
  return httpClient.delete(`/company/profiles/${profileId}`);
}

export async function companyLeaveByCompanyId(companyId) {
  try {
    const res = await httpClient.delete(`/company/${companyId}/leave`);
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

// --- Members ----

export async function fetchAllMembers() {
  try {
    const res = await httpClient.get(`/company/people`);
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

// Referral

export async function fetchCompanyReferral() {
  try {
    const res = await httpClient.get(`/company/referral`);
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function fetchCompanyReferrals() {
  try {
    const res = await httpClient.get(`/company/referrals`);
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function postCompanyReferrals({ referral_code }) {
  try {
    const res = await httpClient.post(`/company/referrals`, {
      referral_code,
    });
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function postCompanyReferralsSend({ email }) {
  try {
    const res = await httpClient.post(`/company/referrals/send`, {
      email,
    });
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function deleteCompanyReferrals({ referralId }) {
  try {
    const res = await httpClient.delete(`/company/referrals/${referralId}`);
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function putCompanyReferralsValidate({ referral_code }) {
  try {
    const res = await httpClient.put(`/company/referrals/validate`, { referral_code });
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function getCompanyReferralsStatistics() {
  try {
    const res = await httpClient.get(`/company/referrals/statistics`);
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function getCompanyReferralsInvites({ paginate }) {
  try {
    const res = await httpClient.get(`/company/referrals/invites`, {
      params: {
        paginate,
      },
    });
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}
