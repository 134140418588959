import cntl from "cntl";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { IconChevronLeft } from "@tabler/icons-react";
import { Breadcrumbs, ButtonToggle, Header } from "@dbox/components";

const propTypes = {
  className: PropTypes.string,
  breadcrumbs: PropTypes.array,
  goBackProps: PropTypes.object,
  hasBorderBottom: PropTypes.bool,
  leftSidebarToggle: PropTypes.object,
  rightSidebarToggle: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

function HeaderNavigation({
  children,
  className,
  goBackProps,
  breadcrumbs,
  leftSidebarToggle,
  rightSidebarToggle,
  hasBorderBottom = true,
}) {
  const borderBottom = () => cntl`
    ${hasBorderBottom ? `border-b border-gray-light-6 dark:border-gray-dark-6` : `border-b-0`}
  `;

  const headerNavigationCn = () => cntl`
    z-10
    flex
    px-3
    h-14
    fixed
    xl:sticky
    top-0
    left-0
    w-full
    right-0
    items-center
    xl:px-[3.75rem]
    bg-gray-light-2
    dark:bg-gray-dark-2
    justify-between
    ${borderBottom()}
    ${className ? className : undefined}
  `;

  const leftCn = () => cntl`
    flex
    gap-x-6
    items-center
  `;

  const buttonToggleCn = () => cntl`
    block
    xl:hidden
  `;

  const childrenAndRightToggleCn = () => cntl`
    flex
    gap-x-4
    items-center
  `;

  return (
    <Header className={headerNavigationCn()}>
      <div className={leftCn()}>
        {leftSidebarToggle ? (
          <ButtonToggle id="button-toggle-left-sidebar" className={buttonToggleCn()} {...leftSidebarToggle} />
        ) : null}
        {goBackProps ? <GoBack {...goBackProps} /> : null}
        {breadcrumbs ? <Breadcrumbs data={breadcrumbs} /> : null}
      </div>
      <div className={childrenAndRightToggleCn()}>
        <>{children}</>
        {rightSidebarToggle ? (
          <ButtonToggle isRight id="button-toggle-right-sidebar" className={buttonToggleCn()} {...rightSidebarToggle} />
        ) : null}
      </div>
    </Header>
  );
}

const GoBack = ({ handleGoBackClick }) => {
  const { t } = useTranslation();

  const backAndTitleCn = () => cntl`
    flex
    gap-x-4
    items-center
  `;

  const iconCn = () => cntl`
    w-5
    h-5
    cursor-pointer
    text-gray-light-11
    dark:text-gray-dark-11
  `;

  const textCn = () => cntl`
    text-xl
    font-semibold
    text-gray-light-12
    dark:text-gray-dark-11
  `;

  return (
    <div className={backAndTitleCn()}>
      <IconChevronLeft className={iconCn()} onClick={handleGoBackClick} />
      <div className={textCn()}>{t("sidebar.text.settings")}</div>
    </div>
  );
};

HeaderNavigation.propTypes = propTypes;
export default HeaderNavigation;
