import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { EventEmitter } from "@dbox/core";
import { useTranslation } from "react-i18next";
import { IconMail } from "@tabler/icons-react";
import { MODAL_HEADER_VARIANTS, SIZE } from "@dbox/components/constants";
import { Input, Modal, ModalAction, ModalBody, ModalHeader } from "@dbox/components";

const propTypes = {
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  onClickSubmitBtn: PropTypes.func,
  onClickCancelBtn: PropTypes.func,
};

function ModalSendEmail({ id, title, isOpen, onSubmit, isLoading, onClickCancelBtn, onClickSubmitBtn }) {
  const { t } = useTranslation();

  const ref = useRef();

  return (
    <Modal id={id} isOpen={isOpen} width="min-w-120">
      <ModalHeader
        title={title}
        variant={MODAL_HEADER_VARIANTS.inline}
        onCloseModal={() => onClickCancelBtn({ ref, target: id })}
      />
      <ModalBody>
        <FormInvitePeople formRef={ref} onSubmit={onSubmit} />
      </ModalBody>
      <ModalAction
        hasPaddingTop
        isLoading={isLoading}
        labelCancelBtn={t("button.label.cancel")}
        labelSubmitBtn={t("button.label.confirm")}
        onClickSubmitBtn={() => onClickSubmitBtn({ ref, target: id })}
        onClickCancelBtn={() => onClickCancelBtn({ ref, target: id })}
      />
    </Modal>
  );
}

const FormInvitePeople = ({ formRef, onSubmit }) => {
  const { t } = useTranslation();

  const regexEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const {
    register,
    resetField,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      email: "",
    },
  });

  useEffect(() => {
    let resetInviteModalForm = EventEmitter.addListener("resetModalInviteForm", () => resetField("email"));

    return () => {
      resetInviteModalForm.removeListener("resetModalInviteForm", () => resetField("email"));
    };
  }, [resetField]);

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <Input
        size={SIZE.lg}
        iconLeft={<IconMail />}
        label={t("input.placeholder.email")}
        placeholder={t("input.placeholder.insert_email")}
        formRegistration={register("email", {
          required: {
            value: true,
            message: t("input.error.required_field"),
          },
          pattern: {
            value: regexEmail,
            message: t("input.error.valid_email"),
          },
        })}
        errors={errors}
      />
    </form>
  );
};

ModalSendEmail.propTypes = propTypes;
export default ModalSendEmail;
