export const pluginSpaceBetweenLegendAndChart = {
  beforeInit(chart) {
    const origFit = chart.legend.fit;
    chart.legend.fit = function fit() {
      origFit.bind(chart.legend)();
      this.height += 16;
    };
  },
};

export const pluginTextInsideDoughnut = {
  id: "pluginTextInsideDoughnut",
  beforeDraw(chart, args, options) {
    const ctx = chart.ctx;
    const xCoor = chart.chartArea.left + (chart.chartArea.right - chart.chartArea.left) / 2;
    const yCoor = chart.chartArea.top + (chart.chartArea.bottom - chart.chartArea.top) / 2;
    ctx.save();

    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.font = "600 1rem Inter";
    ctx.fillStyle = options?.colors?.textInsideColor;
    if (options?.totalEmissionScopes) {
      ctx.fillText(`${options?.totalEmissionScopes}`, xCoor, yCoor - 12);
    }
    ctx.restore();

    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.font = "400 1rem Inter";
    ctx.fillStyle = options?.colors?.textInsideColor;
    ctx.fillText("kgCO2e", xCoor, yCoor + 12);
    ctx.restore();
  },
};

export const pluginLabelOutsideDoughnut = {
  id: "pluginLabelOutsideDoughnut",
  afterDraw(chart, args, options) {
    const {
      ctx,
      chartArea: { width, height },
    } = chart;

    chart.data.datasets.forEach((dataset, i) => {
      chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
        const { x, y } = datapoint.tooltipPosition();

        const halfWidth = width / 2;
        const halfHeight = height / 2;

        const xLine = x >= halfWidth ? x + 20 : x - 20;
        const yLine = y >= halfHeight ? y + 20 : y - 20;
        const extraLine = x >= halfWidth ? 20 : -20;

        if (chart.data.datasets[0].data[index] > 0) {
          ctx.beginPath();
          ctx.moveTo(x, y);
          ctx.lineTo(xLine, yLine);
          ctx.lineTo(xLine + extraLine, yLine);
          ctx.strokeStyle = dataset.borderColor[index];
          ctx.stroke();

          ctx.font = "400 0.75rem Inter";

          const textXPosition = x >= halfWidth ? "left" : "right";
          const plusFivePx = x >= halfWidth ? 10 : -10;
          ctx.textAlign = textXPosition;
          ctx.textBaseline = "middle";
          ctx.fillStyle = options?.colors?.labelsOutside?.top;
          ctx.fillText(
            chart.data.datasets[0].data[index]?.toFixed(2) + " kg",
            xLine + extraLine + plusFivePx,
            yLine + 8
          );

          const sumOfScopes = options?.sumOfScopes;

          ctx.fillText(
            ((chart.data.datasets[0].data[index] * 100) / sumOfScopes)?.toFixed() + " %",
            xLine + extraLine + plusFivePx,
            yLine - 8
          );
        }
      });
    });
  },
};
