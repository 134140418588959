import cntl from "cntl";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import LayoutPage from "components/templates/LayoutPage";
import { openNotification } from "@dbox/core/actions/common/ui";
import { IconDotsVertical, IconTrash } from "@tabler/icons-react";
import { useMatches } from "react-router";
import ModalSendEmail from "../../components/organisms/modal/ModalSendEmail";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  COLOR,
  POSITIONS,
  BUTTON_VARIANTS,
  MODAL_HEADER_VARIANTS,
  NOTIFICATION_TYPES,
  NOTIFICATION_VARIANTS,
} from "@dbox/components/constants";
import { deleteCompanyProfile, fetchAllCompanyEmployees, sendCompanyProfiles } from "@dbox/core/api/aldys/company";
import {
  Modal,
  ModalHeader,
  ModalAction,
  ButtonDropdown,
  CellBodyDatagrid,
  DataGrid,
  TextLink,
} from "@dbox/components";
import { format } from "date-fns";

function Members() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const matches = useMatches();

  const [allCompanyProfiles, setAllCompanyProfiles] = useState([]);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState({
    remove: false,
    invite: false,
    members: true,
  });
  const [removeModal, setRemoveModal] = useState({
    value: null,
    isOpen: false,
  });

  const datagridCn = () => cntl`
  h-[calc(100vh_-_7rem)]
`;

  const breadcrumbs =
    matches
      .filter((match) => Boolean(match.handle?.crumb))
      .map((match) => match.handle.crumb())
      ?.at(-1) || [];

  const layoutProps = {
    headerNavigation: {
      breadcrumbs,
    },
  };

  const handleSubmit = ({ ref, target }) => {
    if (target === "inviteModal") {
      ref.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    }

    if (target === "removeModal") {
      handleRemoveOrganization(removeModal.value);
    }
  };

  const handleCancel = ({ target }) => {
    if (target === "inviteModal") {
      setIsInviteModalOpen(false);
    }

    if (target === "removeModal") {
      setRemoveModal({ isOpen: false, value: null });
    }
  };

  const getAllCompanyProfiles = useCallback(
    async ({ ignore }) => {
      setIsLoading((prev) => ({ ...prev, members: true }));
      try {
        if (!ignore) {
          const profiles = await fetchAllCompanyEmployees({ paginate: false });
          setAllCompanyProfiles(profiles?.employees);
        }
      } catch (err) {
        if (err?.response?.status?.toString() === "500") {
          dispatch(
            openNotification({
              title: t("notification.error.500.title.server_error"),
              message: t("notification.error.500.message.server_error"),
              timeStamp: format(new Date(), "iii ii, hh:mm:ss"),
              type: NOTIFICATION_TYPES.error,
              variant: NOTIFICATION_VARIANTS.toast,
            })
          );
        } else {
          dispatch(
            openNotification({
              title: t("notification.error.500.title.generic_error"),
              message: t("notification.error.500.message.generic_error"),
              timeStamp: format(new Date(), "iii ii, hh:mm:ss"),
              type: NOTIFICATION_TYPES.error,
              variant: NOTIFICATION_VARIANTS.toast,
            })
          );
        }
      } finally {
        setIsLoading((prev) => ({ ...prev, members: false }));
      }
    },
    [t, dispatch]
  );

  const handleInviteSubmit = useCallback(
    async (values) => {
      setIsLoading((prev) => ({ ...prev, invite: true }));
      try {
        await sendCompanyProfiles(values);

        dispatch(
          openNotification({
            title: t("notification.success.200.title.collaboration_request"),
            message: t("notification.success.200.message.collaboration_request"),
            timeStamp: format(new Date(), "iii ii, hh:mm:ss"),
            type: NOTIFICATION_TYPES.success,
            variant: NOTIFICATION_VARIANTS.toast,
          })
        );

        getAllCompanyProfiles({ ignore: false });
        setIsInviteModalOpen(false);
      } catch (err) {
        if (err?.response?.status?.toString() === "500") {
          dispatch(
            openNotification({
              title: t("notification.error.500.title.server_error"),
              message: t("notification.error.500.message.server_error"),
              timeStamp: format(new Date(), "iii ii, hh:mm:ss"),
              type: NOTIFICATION_TYPES.error,
              variant: NOTIFICATION_VARIANTS.toast,
            })
          );
        } else {
          dispatch(
            openNotification({
              title: t("notification.error.500.title.generic_error"),
              message: t("notification.error.500.message.generic_error"),
              timeStamp: format(new Date(), "iii ii, hh:mm:ss"),
              type: NOTIFICATION_TYPES.error,
              variant: NOTIFICATION_VARIANTS.toast,
            })
          );
        }
      } finally {
        setIsLoading((prev) => ({ ...prev, invite: false }));
      }
    },
    [t, dispatch, getAllCompanyProfiles]
  );

  const handleRemoveOrganization = useCallback(
    async (profileId) => {
      setIsLoading((prev) => ({ ...prev, remove: true }));
      try {
        await deleteCompanyProfile(profileId);

        dispatch(
          openNotification({
            title: t("notification.success.200.title.remove_collaborator"),
            message: t("notification.success.200.message.remove_collaborator"),
            timeStamp: format(new Date(), "iii ii, hh:mm:ss"),
            type: NOTIFICATION_TYPES.success,
            variant: NOTIFICATION_VARIANTS.toast,
          })
        );

        getAllCompanyProfiles({ ignore: false });
        setRemoveModal({ isOpen: false, value: null });
      } catch (err) {
        if (err?.response?.status?.toString() === "500") {
          dispatch(
            openNotification({
              title: t("notification.error.500.title.server_error"),
              message: t("notification.error.500.message.server_error"),
              timeStamp: format(new Date(), "iii ii, hh:mm:ss"),
              type: NOTIFICATION_TYPES.error,
              variant: NOTIFICATION_VARIANTS.toast,
            })
          );
        } else {
          dispatch(
            openNotification({
              title: t("notification.error.500.title.generic_error"),
              message: t("notification.error.500.message.generic_error"),
              timeStamp: format(new Date(), "iii ii, hh:mm:ss"),
              type: NOTIFICATION_TYPES.error,
              variant: NOTIFICATION_VARIANTS.toast,
            })
          );
        }
      } finally {
        setIsLoading((prev) => ({ ...prev, remove: false }));
      }
    },
    [t, dispatch, getAllCompanyProfiles]
  );

  useEffect(() => {
    let ignore = false;
    getAllCompanyProfiles({ ignore });

    return () => {
      ignore = true;
    };
  }, [getAllCompanyProfiles]);

  const columns = useMemo(
    () => [
      {
        id: "id",
        accessorKey: "id",
        header: t("table.header.id"),
        enableSorting: false,
        cell: (cellInfo) => {
          return (
            <CellBodyDatagrid
              className="px-6"
              isLeading
              text={
                <TextLink label={`EMP-${cellInfo?.getValue()}` || "n/a"} to={`/employee/${cellInfo.row.original.id}`} />
              }
            />
          );
        },
      },

      {
        id: "name",
        accessorKey: "name",
        header: t("table.header.name"),
        enableSorting: false,
        cell: (cellInfo) => {
          return (
            <CellBodyDatagrid
              className="px-6"
              text={t(`${cellInfo.row.original.first_name} ${cellInfo.row.original.last_name}`) || "n/a"}
            />
          );
        },
      },
      {
        id: "office",
        accessorKey: "office",
        header: t("table.header.office"),
        enableSorting: false,
        cell: (cellInfo) => {
          return <CellBodyDatagrid className="px-6" text={cellInfo.row.original.office_id} />;
        },
      },
      {
        id: "residence",
        accessorKey: "residence",
        header: t("table.header.residence"),
        enableSorting: false,
        cell: (cellInfo) => {
          return (
            <CellBodyDatagrid
              className="px-6"
              text={
                t(
                  `${cellInfo.row.original.address.street}, ${cellInfo.row.original.address.postcode}, ${cellInfo.row.original.address.city} (${cellInfo.row.original.address.region})`
                ) || "n/a"
              }
            />
          );
        },
      },
      {
        id: "records",
        accessorKey: "records",
        header: t("table.header.records"),
        enableSorting: false,
        cell: (cellInfo) => {
          const recordCount = cellInfo.getValue() ? cellInfo.getValue().toString() : "0";
          return <CellBodyDatagrid className="px-6" text={recordCount} />;
        },
      },
      {
        enableSorting: false,
        id: "deleteCollaborator",
        cell: (cellInfo) => {
          const dropdownCn = () => cntl`
            ${cellInfo?.row?.original?.type?.toLowerCase() !== "administrator" ? `visible` : `invisible`}
          `;

          const iconCn = () => cntl`
            w-4
            h-4
            shrink-0
            text-gray-light-11
            dark:text-gray-dark-11
          `;

          const menuItems = [
            {
              text: t("dropdown.text.delete"),
              value: cellInfo?.row?.original?.id,
              children: <IconTrash className={iconCn()} />,
              onSelect: (id) => setRemoveModal({ isOpen: true, value: id }),
            },
          ];

          const dropdownProps = {
            menuItems,
            className: dropdownCn(),
            icon: <IconDotsVertical />,
            variant: BUTTON_VARIANTS.ghost,
          };

          return (
            <CellBodyDatagrid className="px-6" textAlign={POSITIONS.right}>
              <ButtonDropdown {...dropdownProps} />
            </CellBodyDatagrid>
          );
        },
      },
    ],
    [t]
  );

  const modalSendEmailProps = {
    onClickCancelBtn: handleCancel,
    onClickSubmitBtn: handleSubmit,
    id: "inviteModal",
    isOpen: isInviteModalOpen,
    isLoading: isLoading.invite,
    onSubmit: handleInviteSubmit,
    title: t("modal.header.title.invite_user"),
  };

  const modalRemoveCollaboratosProps = {
    handleCancel,
    handleSubmit,
    isLoading: isLoading.remove,
    isDeleteModalOpen: removeModal.isOpen,
  };

  const datagridProps = {
    columns,
    hiddenHeader: false,
    hasPagination: false,
    data: allCompanyProfiles,
    isLoading: isLoading.members,
    className: datagridCn(),
    fetch: getAllCompanyProfiles,
  };

  return (
    <>
      <ModalSendEmail {...modalSendEmailProps} />
      <ModalRemoveCollaborator {...modalRemoveCollaboratosProps} />
      <LayoutPage {...layoutProps}>
        <DataGrid {...datagridProps} />
      </LayoutPage>
    </>
  );
}

const ModalRemoveCollaborator = ({ isLoading, isDeleteModalOpen, handleCancel, handleSubmit }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isDeleteModalOpen}>
      <ModalHeader
        hasPaddingBottom
        variant={MODAL_HEADER_VARIANTS.inline}
        title={t("modal.header.title.delete_collaborator")}
        text={t("modal.header.text.info_delete_collaborator")}
        onCloseModal={() => handleCancel({ target: "removeModal" })}
      />
      <ModalAction
        hasPaddingTop
        color={COLOR.error}
        isLoading={isLoading}
        labelCancelBtn={t("button.label.cancel")}
        labelSubmitBtn={t("button.label.delete")}
        onClickSubmitBtn={() => handleSubmit({ target: "removeModal" })}
        onClickCancelBtn={() => handleCancel({ target: "removeModal" })}
      />
    </Modal>
  );
};

export default Members;
