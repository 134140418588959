import cntl from "cntl";
import PropTypes from "prop-types";
import { Avatar } from "@dbox/components";
import { SIZE } from "@dbox/components/constants";

const propTypes = {
  title: PropTypes.string,
  image: PropTypes.object,
  subTitle: PropTypes.string,
  className: PropTypes.string,
  hasBorderBottom: PropTypes.bool,
};

function PageHeader({ title, subTitle, image = null, className, hasBorderBottom = false }) {
  const wrapperTextCn = () => cntl`
    flex
    w-full
    gap-y-1
    flex-col
    ${hasBorderBottom ? cntl`pb-6 border-b border-b-gray-light-6 dark:border-b-gray-dark-6` : cntl`border-b-0`}
  `;

  const iconAndTitleAndSubTitleCn = () => cntl`
    mt-4
    flex
    xl:mt-0
    gap-x-5
    flex-row
    items-center
    ${className ? className : undefined}
  `;

  const titleCn = () => cntl`
    text-2xl
    font-medium
    text-gray-light-12
    dark:text-gray-dark-12
    first-letter:uppercase
  `;

  const subTitleCn = () => cntl`
    text-sm
    text-gray-light-11
    dark:text-gray-dark-11
    first-letter:uppercase
  `;

  return (
    <div className={iconAndTitleAndSubTitleCn()}>
      {image && <Avatar size={SIZE["2xl"]} picture={image} />}
      <div className={wrapperTextCn()}>
        {title ? <div className={titleCn()}>{title}</div> : null}
        {subTitle ? <div className={subTitleCn()}>{subTitle}</div> : null}
      </div>
    </div>
  );
}

PageHeader.propTypes = propTypes;
export default PageHeader;
