import cntl from "cntl";
import { PropTypes } from "prop-types";
import PageHeader from "components/atoms/header/PageHeader";
import HeaderNavigation from "components/molecules/header/HeaderNavigation";
import { EventEmitter } from "@dbox/core";
import { useEffect } from "react";
import SubHeader from "components/atoms/header/SubHeader";

const propTypes = {
  header: PropTypes.object,
  subHeader: PropTypes.object,
  className: PropTypes.string,
  headerNavigation: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

function LayoutPage({ header, subHeader, children, className, headerNavigation }) {
  const layoutPageCn = () => cntl`
    mx-auto
    ${className ? className : undefined}
  `;

  const leftSidebarToggle = {
    onClick: () => EventEmitter.emit("toggleLeftSidebar"),
  };

  const headerNavigationProps = {
    leftSidebarToggle,
    ...headerNavigation,
  };

  useEffect(() => {
    EventEmitter.emit("closeLeftSidebar");
  }, []);

  return (
    <div className={layoutPageCn()}>
      {headerNavigation ? (
        <HeaderNavigation {...headerNavigationProps}>{headerNavigation.children}</HeaderNavigation>
      ) : null}
      {header ? <PageHeader {...header} /> : null}
      {SubHeader ? <SubHeader {...subHeader} /> : null}
      <div>{children}</div>
    </div>
  );
}

LayoutPage.propTypes = propTypes;
export default LayoutPage;
