import { get } from "lodash";
import { httpClient } from "../../httpClient";

export async function getChartEnumsYears() {
  try {
    const res = await httpClient.get(`/chart/enums/years`);

    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function getChartOverviewYearly({ year }) {
  try {
    const res = await httpClient.get(`/chart/overview/yearly?year=${year}`);

    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function getChartOverviewYearlyDetails({ year, office_id, employee_id }) {
  try {
    const res = await httpClient.get(`/chart/overview/yearly/details`, {
      params: {
        year,
        office_id,
        employee_id,
      },
    });

    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function getChartOverviewCompany({ year }) {
  try {
    const res = await httpClient.get(`/chart/overview/company`, {
      params: {
        year,
      },
    });

    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function getChartOverviewCompanyCategories({ year }) {
  try {
    const res = await httpClient.get(`chart/overview/company/categories`, {
      params: {
        year,
      },
    });

    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function getChartOverviewMonthly({ month, year }) {
  try {
    const res = await httpClient.get(`/chart/overview/monthly?month=${month}&year=${year}`);

    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function getChartCompanyKpy() {
  try {
    const res = await httpClient.get(`/chart/company/kpy`);

    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}
