import cntl from "cntl";
import PropTypes from "prop-types";

// Components
import Typography from "../typography/Typography";

// Constants
import { SIZE } from "@dbox/components/constants";

const propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
};

const SubHeader = ({ title, text, className }) => {
  const subHeaderCn = () => cntl`
    flex
    gap-y-1
    flex-col
    ${className ? className : undefined}
  `;

  const typographyTitleProps = {
    size: SIZE.base,
  };

  const typographyTextProps = {
    size: SIZE.sm,
  };

  return (
    <div className={subHeaderCn()}>
      <Typography {...typographyTitleProps}>{title}</Typography>
      {text ? <Typography {...typographyTextProps}>{text}</Typography> : null}
    </div>
  );
};

SubHeader.propTypes = propTypes;
export default SubHeader;
